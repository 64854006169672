import React from "react"
import { graphql, Link } from "gatsby"
import {Helmet} from "react-helmet"
import Layout from "../components/layout"
import BlueDots from "../../static/images/support-blue-dots.svg"
import BlueDot from "../../static/images/support-blue-dot.svg"
import BlueCicle from "../../static/images/support-blue-circle.svg"
import BlueDots2 from "../../static/images/support-blue-dots-2.svg"



export default function Faqs({ data }) {
  const { questions } = data.support

  // // Get a unique set of categories
  // const categories = [...new Set(faqs.map(f => f.frontmatter.category))]

  // helper to get faqs for each category
  const faqsForCategory = category => {
    const topics = questions.filter(f => f.frontmatter.category === category)
    return [topics, topics.length]
  }

  const [faqs, faqsCount] = faqsForCategory("faqs")
  const [troubleshooting, troubleshootingCount] = faqsForCategory(
    "troubleshooting"
  )

  return (
    <Layout>
      <Helmet>
        <title>Support | IBDmate</title>
      </Helmet>
      <div className="layout-support">
        <div className="blue-bg">
          <form>
            <h1>How can we help?</h1>
          </form>
          <div className="vectors-left">
            <img src={BlueDots} id="blue-dots" alt="" />
            <img src={BlueDot} id="blue-dot" alt="" />
          </div>
          <div className="vectors-right">
            <img src={BlueCicle} id="blue-circle" alt="" />
            <img src={BlueDots2} id="blue-dots-2" alt="" />
          </div>
        </div>
        <div className="wrapper">
          <div className="left-column">
            <h2>FAQs</h2>
            {faqs.slice(0, 10).map(question => (
              <article key={question.id}>
                <Link to={question.fields.slug}>
                  {question.frontmatter.question}
                </Link>
              </article>
            ))}
            {faqsCount > 10 && (
              <Link to="/support/faqs/" className="btn btn-purple">
                View all FAQs
              </Link>
            )}
          </div>
          <div className="right-column">
            <h2>Troubleshooting</h2>
            {troubleshooting.slice(0, 10).map(question => (
              <article key={question.id}>
                <Link to={question.fields.slug}>
                  {question.frontmatter.question}
                </Link>
              </article>
            ))}
            {troubleshootingCount > 10 && (
              <Link to="/support/troubleshooting/" className="btn btn-purple">
                View all troubleshooting
              </Link>
            )}
            <div className="contact">
              <h2>Contact</h2>
              <p>If you would like to get in touch with IBDmate email us on <a href="mailto:info@ibdmate.com">info@ibdmate.com</a></p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    support: allMarkdownRemark(
        sort:{
          order:ASC,
          fields: [frontmatter___sort]
        }
      ) {
      questions: nodes {
        fields {
          slug
        }
        frontmatter {
          question
          category
          categoryTitle
        }
        excerpt
        id
      }
    }
  }
`
